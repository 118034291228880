import React from "react"
import Layout from "../components/global/layout"
import SEO from "../components/global/seo"
import SingleProduct from "../components/single-product-page/singleProduct"

import moisturiserCherryProduct from "../images/product-and-package/mc.jpg"
import moisturiserCherryImage from "../images/products-without-packaging/moisturiser-cherry.png"

// related product images
import intensiveMoisturiserProduct from "../images/product-and-package/inm.jpg"
import medPlusProduct from "../images/product-and-package/mp.jpg"

import coldIcon from "../images/icons/product-icon/winter.svg"
import everydayIcon from "../images/icons/product-icon/everyday.svg"
import outdoorIcon from "../images/icons/product-icon/outdoors.svg"
import overnightIcon from "../images/icons/product-icon/overnight.svg"

import BSGoldAward from "../images/awards/BS-Award-Gold.png"

import amazonLogo from "../images/stockists/amazon.png"
import tescoLogo from "../images/stockists/tesco.png"
import sainsburysLogo from "../images/stockists/sainsburys.png"
import bodycareLogo from "../images/stockists/bodycare.png"
import saversLogo from "../images/stockists/savers.png"

const product = {
  image: moisturiserCherryProduct,
  productImage: moisturiserCherryImage,
  title: (
    <h1 className="product-overview__heading">Intensive Moisturiser Cherry.</h1>
  ),
  alt: "Intensive Moisturiser Cherry.",
  subTitle: (
    <h2 className="product-overview__sub-heading">
      <br />
      Intensive Moisturiser Cherry is infused with a blend of rich moisturisers
      like shea butter to condition and allantoin to smooth.
    </h2>
  ),
  awards: [{ title: "GOLD and BEAUTY STEAL in LIP BALM", img: BSGoldAward }],
  icons: [
    { title: "everyday", icon: everydayIcon },
    { title: "outdoors", icon: outdoorIcon },
    { title: "cold weather", icon: coldIcon },
    { title: "at night", icon: overnightIcon },
  ],
  disclaimer: "",
  overviewCopy: (
    <div>
      <br />
      <p>
        it moisturises deep down to intensely hydrate your lips, with a hint of
        cherry.
      </p>
    </div>
  ),
  listID: "",
  stockists: [
    {
      stockistName: "Amazon",
      stockistLink: "https://www.amazon.co.uk/dp/B07H7T9P7M",
      stockistImage: amazonLogo,
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "Sainsbury's",
      stockistLink: "",
      stockistImage: sainsburysLogo,
      classModifier: "",
      showLink: "",
    },
    {
      stockistName: "Bodycare",
      stockistImage: bodycareLogo,
      stockistLink:
        "https://www.bodycareonline.co.uk/product/blistex-intensive-moisturiser-cherry-6ml/",
      classModifier: "",
      showLink: "",
    },
    {
      stockistName: "Tesco",
      stockistImage: tescoLogo,
      stockistLink: "https://www.tesco.com/groceries/en-GB/products/295036178",
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "Savers",
      stockistImage: saversLogo,
      stockistLink:
        "https://www.savers.co.uk/Skin-Care/Lip-Care/Lip-Balms/Blistex-Intensive-Moisturiser-Cherry/p/830979",
      classModifier: "",
      showLink: "true",
    },
  ],
  whenToUseCopy: (
    <div>
      <p>
        gently massage all over your lips every day. Re-apply as often as you
        like, especially if you're out and about in dry, cold or sunny weather.
      </p>
    </div>
  ),
  ingredients: (
    <p>
      aqua, hydrogenated polysiobutene, cera alba, petrolatum, ethylhexyl
      methoxycinnamate, aroma, polyglyceryl-3 diisostearate, sorbitan
      isostearate, hydrogenated soy polyglycerides, glycerin, polysorbate 60,
      cetyl alcohol, stearyl behenate, dimethicone, phenoxyethanol, allantoin,
      arachidyl alcohol, arachidyl glucoside, behenyl alcohol, butyrospermum
      parkii (shea) butter, C15-23 alkane, calcium disodium EDTA, citric acid,
      hydrated silica, lanolin alcohol, lauric acid, myristic acid, oleic acid,
      palmitic acid, phosphoric acid, sodium hydroxide, sodium saccharin,
      sorbitan olivate.
    </p>
  ),
  faqs: [
    {
      question:
        "Does Intensive Moisturiser Cherry contain a sun protection factor?",
      answer: "Yes, Intensive Moisturiser Cherry contains SPF 15.",
    },
    {
      question:
        "The packaging for Intensive Moisturiser Cherry has changed - is the formula still the same?",
      answer:
        "Yes, although the packaging has been updated, the same trusted formulas remain.",
    },
    {
      question:
        "Does this product contain any ingredients derived from animals?",
      answer: "Yes, Beeswax and Lanolin Alcohol",
    },
    {
      question: "Do you sell this product online?",
      answer:
        "Intensive Moisturiser Cherry is available to buy online through key retailers.  Please click on the where to buy link on each product page.",
    },
    {
      question: "Does this product contain perfume?",
      answer: "No, only flavourings.",
    },
    {
      question: "Does this product contain any parabens?",
      answer: "No.",
    },
  ],
  relatedProducts: [
    {
      productName: "intensive moisturiser.",
      productLink: "/intensive-moisturiser",
      productImage: intensiveMoisturiserProduct,
    },
    {
      productName: "MedPlus Jar.",
      productLink: "/medplus",
      productImage: medPlusProduct,
    },
  ],
}

const IntensiveMoisturiserCherry = () => (
  <Layout noHeaderInLayout={false} makeFooterSticky={false}>
    <SEO title="Intensive Moisturiser Cherry." />
    <SingleProduct data={product} inStock={true} outOfStockMsg={null} />
  </Layout>
)

export default IntensiveMoisturiserCherry
